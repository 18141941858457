* {
    margin: 0;
    padding: 0;
}

body, html, #root {
    background: #dfe2ed !important;
    height: 100%;
}

ul[role=listbox] {
    max-height: 300px;
}
